import { createSlice } from "@reduxjs/toolkit";
// utils
import { dispatch } from "../store";

//services
import RegisterService from "../../services/RegisterService";
import ForgotService from "../../services/ForgotService";
// ----------------------------------------------------------------------

const initialState = {
    loading: false,
    error: null,
    success: false,
    otpResendSuccess: false,
    resetPassSuccess: false,
    step: 1,
    registerBody: {},
    otpLoading: false,
    // const [, setStep] = useState(1);
};

const slice = createSlice({
    name: "forgot",
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        forgotSetStep(state, action) {
            state.step = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.loading = false;
            console.log("error ni yu ireed bna", action);
            state.error = action.payload;
        },
        //OTP verify success
        otpVerifySuccess(state, action) {
            state.loading = false;
            console.log("action ni yu ireed bna", action);
            state.success = true;
            state.step = state.step + 1;
            state.error = null;

        },
        //OTP resend success
        otpResendSuccess(state, action) {
            state.loading = false;
            console.log("action ni yu ireed bna", action);
            state.otpResendSuccess = true;
            state.error = null;
        },
        initialStateForgot(state) {
            state.loading = false;
            state.error = null;
            state.success = false;
            state.step = 1;
            state.otpResendSuccess = false;
            state.registerSuccess = false;
        },
        hideError(state) {
            state.error = null;
            state.otpResendSuccess = false;
        },
        //Reset otp success
        resetOtpSuccess(state, action) {
            state.loading = false;
            state.step = state.step + 1;
            console.log("action ni yu ireed bna", action);
            state.error = null;
        },
        //Reset pass success
        resetPassSuccess(state, action) {
            state.loading = false;
            state.step = state.step + 1;
            console.log("action ni yu ireed bna", action);
            state.resetPassSuccess = true;
            state.error = null;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading, hideError, forgotSetStep, initialStateRegister } =
    slice.actions;

// // ----------------------------------------------------------------------

export function resetOtpThunk(phoneNumber) {
    console.log("body ni yu baina", phoneNumber);
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await ForgotService.otpResend(phoneNumber);
            dispatch(slice.actions.resetOtpSuccess());
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function forgotOtpVeriyThunk({ phoneNumber, otpValue }) {
    console.log("body ni yu baina", phoneNumber);
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await RegisterService.otpVerify({ phoneNumber, otpValue });
            dispatch(slice.actions.otpVerifySuccess());
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            console.log("error ni yu baina", error);
        }
    };
}

export function forgotOtpResendThunk({ phoneNumber }) {
    console.log("body ni yu baina", phoneNumber);
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await ForgotService.otpResend(phoneNumber);
            dispatch(slice.actions.otpResendSuccess());
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            console.log("error ni yu baina", error);
        }
    };
}
export function resetPassAddThunk(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await ForgotService.resetPassword(data);
            dispatch(slice.actions.resetPassSuccess());
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            console.log("error ni yu baina", error);
            // dispatch(slice.actions.createCustomerSuccess());
        }
    };
}