import Typography from "../../base-components/Typography";

const ComingSoon = () => {
  return (
    <div className="grid place-content-center h-full">
      <Typography variant="Display" size="sm" className="text-center mt-12">
        Тун удахгүй...
      </Typography>
    </div>
  )
}

export default ComingSoon;