export const mongolUseg = [
  { name: "А" },
  { name: "Б" },
  { name: "В" },
  { name: "Г" },
  { name: "Д" },
  { name: "Е" },
  { name: "Ё" },
  { name: "Ж" },
  { name: "З" },
  { name: "И" },
  { name: "Й" },
  { name: "К" },
  { name: "Л" },
  { name: "М" },
  { name: "Н" },
  { name: "О" },
  { name: "Ө" },
  { name: "П" },
  { name: "Р" },
  { name: "С" },
  { name: "Т" },
  { name: "У" },
  { name: "Ү" },
  { name: "Ф" },
  { name: "Х" },
  { name: "Ц" },
  { name: "Ч" },
  { name: "Ш" },
  { name: "Э" },
  { name: "Ю" },
  { name: "Я" },
];

export const tokenKey = "token";

export const tmpTokenKey = "tmpToken";