export const isNullOrUndefined = (value) => {
  return typeof value === "undefined" || value === null;
}

export function isEmptyObject(params) {
  return typeof params === "object" && Object.keys(params).length === 0;
}

// export const formatNumber = (inputNumber) => {
//   if (typeof inputNumber !== "number") return null;
//   let formetedNumber = Number(inputNumber).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
//   let splitArray = formetedNumber.split('.');
//   if (splitArray.length > 1)
//     formetedNumber = splitArray[0];
//   return formetedNumber;
// };

export const formatNumber = (value, digit = 2) => {
  if (!value)
    return Number(0).toLocaleString(undefined, { minimumFractionDigits: digit });
  let number = value;
  if (typeof value === "string")
    number = value.replace(/,/g, "");
  if (isNaN(Number(number))) {
    let text = number?.slice(0, number.length - 1);
    return Number(text).toLocaleString(undefined, { minimumFractionDigits: digit });
  }
  return Number(number).toLocaleString(undefined, { minimumFractionDigits: digit })
}

export const formatPlateNumber = (value) => {
  let number = value;
  if (value.length > 4) {
    let lastValue = value.substring(value.length - 1, value.length);
    let text = value;
    if (/[а-яөү]/i.test(lastValue))
      text = value.slice(0, value.length - 1).concat(lastValue.toUpperCase());
    else
      text = value.slice(0, value.length - 1);
    return text;
  }
  if (typeof value === "string")
    number = value.replace(/,/g, "");
  if (isNaN(Number(number))) {
    let text = number.slice(0, number.length - 1);
    return text;
  }
  if (value === "")
    return value;
  return number;
}

export const formatMobile = (value) => {
  let number = value;
  if (typeof value === "string")
    number = value.replace(/,/g, "");
  if (isNaN(Number(number))) {
    let text = number.slice(0, number.length - 1);
    return Number(text);
  }
  return Number(number);
}

export function roundUp(num, precision) {
  precision = Math.pow(10, precision)
  return Math.ceil(num * precision) / precision
}

export const getSequence = (a, b) => {
  return a.sequence - b.sequence;
}