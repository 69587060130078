import React from "react";
import SavingsSection from "./savings";
import CardSection from "./cart";
import SavingsSummary from "./saving_summary";

const HomePage = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <CardSection></CardSection>
        <SavingsSummary></SavingsSummary>
      </div>
    </div>
  );
};

export default HomePage;
