import { useFormContext } from "react-hook-form";

import Button from "../../../base-components/Button";
import Typography from "../../../base-components/Typography";
import { twMerge } from "tailwind-merge";
import Input from "../../../base-components/Input";
import { resetOtpThunk } from "../../../redux/slices/forgot";
import { dispatch, useSelector } from "../../../redux/store";
import { PulseLoader } from "react-spinners";

const Mobile = () => {
  const { loading } = useSelector((state) => state.forgot);
  const {
    register,
    formState: { errors },
    watch,
    trigger,
  } = useFormContext();

  const getOtpBySms = (e) => {
    e.preventDefault();
    const phoneNumber = watch("phoneNumber");
    trigger("phoneNumber", { shouldFocus: true });
    console.log("phoneNumber", phoneNumber);
    if (errors?.phoneNumber && !phoneNumber) {
      return;
    }
    dispatch(resetOtpThunk(phoneNumber));
    // setStep(step + 1);
  };

  return (
    <>
      <div>
        <Typography className="text-on-surface-black-64">
          Та бүртгэлтэй утасны дугаараа оруулна уу.
        </Typography>
        <div>
          <Input
            id="recover-phoneNumber"
            type="number"
            placeholder="Утасны дугаар"
            {...register("phoneNumber", {
              required: true,
              maxLength: 8,
              pattern: /^[7-9]{1}?[0-9]{7}$/,
            })}
            className="w-full"
          />
          {errors?.phoneNumber?.type === "required" && (
            <p className="text-error text-xs pl-2 mt-1">
              Утасны дугаараа оруулна уу!
            </p>
          )}
          {errors?.phoneNumber?.type === "maxLength" && (
            <p className="text-error text-xs pl-2 mt-1">
              Утасны дугаар авах утгын урт хэтэрсэн байна!
            </p>
          )}
          {errors?.phoneNumber?.type === "pattern" && (
            <p className="text-error text-xs pl-2 mt-1">
              Утасны дугаар буруу байна. Шалгана уу!
            </p>
          )}
          {errors?.phoneNumber?.type === "custom" && (
            <p className="text-error text-xs pl-2 mt-1">
              {errors?.phoneNumber?.message}
            </p>
          )}
        </div>
      </div>
      <div
        className={twMerge(["justify-center mt-2 hidden", loading && "flex"])}
      >
        <PulseLoader
          color="#BBC1C9"
          speedMultiplier={0.7}
          loading={loading}
          size={12}
        />
      </div>
      <div className="mt-6 flex justify-end items-center">
        <Button
          onClick={(e) => getOtpBySms(e)}
          disabled={watch("phoneNumber") === ""}
        >
          Үргэлжлүүлэх
        </Button>
      </div>
    </>
  );
};

export default Mobile;
