import React, { useState } from "react";

const AccessHistoryTable = () => {
  // Sample data
  const data = [
    {
      date: "2024.03.12",
      ip: "11.111.111.111",
      deviceType: "Гар утас",
      deviceDetails: "iPhone",
      accessCount: 10,
    },
    {
      date: "2024.03.12",
      ip: "11.111.111.111",
      deviceType: "Компьютер",
      deviceDetails: "Lenovo",
      accessCount: 5,
    },
    {
      date: "2024.03.12",
      ip: "11.111.111.111",
      deviceType: "Компьютер",
      deviceDetails: "HP",
      accessCount: 15,
    },
    {
      date: "2024.03.15",
      ip: "11.111.111.111",
      deviceType: "Гар утас",
      deviceDetails: "Samsung",
      accessCount: 7,
    },
    {
      date: "2024.03.11",
      ip: "11.111.111.111",
      deviceType: "Гар утас",
      deviceDetails: "Samsung",
      accessCount: 7,
    },
    {
      date: "2024.03.13",
      ip: "11.111.111.111",
      deviceType: "Гар утас",
      deviceDetails: "Samsung",
      accessCount: 7,
    },
    {
      date: "2024.03.14",
      ip: "11.111.111.111",
      deviceType: "Гар утас",
      deviceDetails: "Samsung",
      accessCount: 7,
    },
  ];

  // Modal state
  const [selectedItem, setSelectedItem] = useState(null);

  // Function to open the modal
  const openModal = (item) => {
    setSelectedItem(item);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedItem(null);
  };

  return (
    <div className="max-w-4xl mx-auto mt-5">
      <div className="bg-white shadow-md rounded-lg p-3">
        <h2 className="text-xl font-semibold mb-4">Хандалтын түүх</h2>
        <div className="overflow-x-auto">
          <table className="w-full table-auto text-left border-collapse">
            <thead>
              <tr className="text-gray-700 bg-gray-200">
                <th className="px-4 py-2">Огноо</th>
                <th className="px-4 py-2">IP хаяг</th>
                <th className="px-4 py-2">Төхөөрөмж</th>
                <th className="px-4 py-2">Төхөөрөмжийн мэдээлэл</th>
                <th className="px-4 py-2">Хандсан тоо</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white border-b hover:bg-gray-50 cursor-pointer"
                  onClick={() => openModal(item)}
                >
                  <td className="px-4 py-2">{item.date}</td>
                  <td className="px-4 py-2">{item.ip}</td>
                  <td className="px-4 py-2">{item.deviceType}</td>
                  <td className="px-4 py-2">{item.deviceDetails}</td>
                  <td className="px-4 py-2">{item.accessCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for details */}
      {selectedItem && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md mx-auto">
            <h3 className="text-lg font-semibold mb-4">Төхөөрөмжийн дэлгэрэнгүй</h3>
            <p><strong>Огноо:</strong> {selectedItem.date}</p>
            <p><strong>IP хаяг:</strong> {selectedItem.ip}</p>
            <p><strong>Төхөөрөмж:</strong> {selectedItem.deviceType}</p>
            <p><strong>Төхөөрөмжийн мэдээлэл:</strong> {selectedItem.deviceDetails}</p>
            <p><strong>Хандсан тоо:</strong> {selectedItem.accessCount}</p>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={closeModal}
              >
                Хаах
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccessHistoryTable;
