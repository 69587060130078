import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Make sure to import the necessary Swiper CSS
const ProductRecommendations = () => {
  const products = [
    {
      id: 1,
      name: "Бүтээгдэхүүн 1",
      imageUrl:
        "https://i.pinimg.com/enabled_hi/564x/d9/57/a4/d957a459a1434e9235b320fb37ae5773.jpg",
    },
    {
      id: 2,
      name: "Бүтээгдэхүүн 2",
      imageUrl:
        "https://i.pinimg.com/enabled_hi/564x/90/bb/bb/90bbbbfd770343e1ce90810406460a8d.jpg",
    },
    {
      id: 3,
      name: "Бүтээгдэхүүн 3",
      imageUrl:
        "https://i.pinimg.com/enabled_hi/564x/fd/19/18/fd191840e0448d130c824b516fdcd6ab.jpg",
    },
    {
      id: 4,
      name: "Бүтээгдэхүүн 4",
      imageUrl:
        "https://i.pinimg.com/enabled_hi/564x/cc/1c/9f/cc1c9f11edcf64c3052a003ec29912bd.jpg",
    },
    // {
    //   id: 5,
    //   name: "Бүтээгдэхүүн 5",
    //   imageUrl: "https://via.placeholder.com/150",
    // },
  ];

  return (
    <div className="bg-gray-200 p-4 rounded-lg shadow-md mt-4">
      <h3 className="font-bold text-lg mb-4">Санал болгож буй бүтээгдэхүүн</h3>
      <Swiper
        className="hidden-arrows"
        spaceBetween={20}
        style={{ zIndex: 0 }}
        slidesPerView={1} // Default view for mobile
        breakpoints={{
          640: {
            slidesPerView: 1, // 1 item on mobile devices (up to 640px)
          },
          768: {
            slidesPerView: 2, // 2 items on tablets (768px and up)
          },
          1024: {
            slidesPerView: 3, // 3 items on larger screens (1024px and up)
          },
        }}
        navigation // Keep navigation functionality
        pagination={{ clickable: true }}
      >
        {products.map((product) => (
          <SwiperSlide
            key={product.id}
            className="flex-shrink-0"
            style={{ cursor: "pointer" }}
          >
            <div className="bg-white rounded-lg shadow-md p-2">
              <img
                src={product.imageUrl}
                alt={product.name}
                className="w-full h-24 object-cover rounded-lg mb-2"
              />
              <p className="text-center font-semibold">{product.name}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <style jsx>{`
        .hidden-arrows .swiper-button-next,
        .hidden-arrows .swiper-button-prev {
          display: none; // Hide the navigation buttons
        }
      `}</style>
    </div>
  );
};

export default ProductRecommendations;
