const UserInfo = () => {
    return <form className="space-y-4">
        <div>
            <label className="block text-sm font-medium mb-1" htmlFor="lastname">Овог</label>
            <input
                type="text"
                id="lastname"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                placeholder="Овог оруулна уу"
            />
        </div>

        <div>
            <label className="block text-sm font-medium mb-1" htmlFor="firstname">Нэр</label>
            <input
                type="text"
                id="firstname"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Нэр оруулна уу"
            />
        </div>

        <div>
            <label className="block text-sm font-medium mb-1" htmlFor="register">Регистерийн дугаар</label>
            <input
                type="text"
                id="register"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Регистерийн дугаар оруулна уу"
            />
        </div>

        <div>
            <label className="block text-sm font-medium mb-1" htmlFor="username">Нэвтрэх нэр</label>
            <input
                type="text"
                id="username"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Нэвтрэх нэр оруулна уу"
            />
        </div>

        <div>
            <label className="block text-sm font-medium mb-1" htmlFor="phone">Утасны дугаар</label>
            <input
                type="text"
                id="phone"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Утасны дугаар оруулна уу"
            />
        </div>

        <div>
            <label className="block text-sm font-medium mb-1" htmlFor="address">Оршин суугаа хаяг</label>
            <input
                type="text"
                id="address"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Оршин суугаа хаяг оруулна уу"
            />
        </div>

        <button
            type="submit"
            className="w-full bg-primary text-white font-bold py-2 rounded-lg hover:bg-primary-dark transition-colors"
        >
            Хадгалах
        </button>
    </form>;
}

export default UserInfo