import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const Checkbox = forwardRef(
  ({ as, label, className, id = "checkbox", ...props }, ref) => {
    return (
      <div className="text-primary flex items-center">
        <input
          type="checkbox"
          className={twMerge([
            "relative h-5 w-5 appearance-none rounded-[0.25rem] border border-on-surface-black-24 flex justify-center outline-none", // Default
            // "disabled:border-on-surface-black-24", // Disabled
            // Before
            "before:pointer-events-none before:absolute before:h-[18px] before:w-[18px] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-['']",
            // Hover
            "hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)]",
            "focus:shadow-none focus:transition-[border-color_0.2s]", // Focus
            // Focus-before
            "focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s]",
            // Focus-after
            "focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-['']",
            "checked:border-primary checked:bg-primary", // Checked
            "checked:disabled:bg-on-surface-black-24", // Checked-disabled
            "checked:before:opacity-[0.16]", // Checked-before
            // Checked-after
            "checked:after:absolute checked:after:h-[0.8125rem] checked:after:w-1.5 checked:after:top-[1px] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:rounded-br-sm checked:after:border-white checked:after:bg-transparent checked:after:content-['']",
            // Checked-focus-before
            "checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]",
            // Checked-focus-after
            "checked:focus:after:h-[0.8125rem] checked:focus:after:w-1.5 checked:focus:after:top-[1px] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:rounded-br-sm checked:focus:after:border-white checked:focus:after:bg-transparent",
            className,
          ])}
          id={id}
          {...props}
        />
        <label
          className="inline-block pl-2 text-sm font-semibold hover:cursor-pointer"
          htmlFor={id}
        >
          {label}
        </label>
      </div>
    );
  }
);

export default Checkbox;
