const ChangePassword = () => {
    return (
        <>
            <form>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1" htmlFor="oldPassword">
                        Одоогийн нууц үг
                    </label>
                    <input
                        type="password"
                        id="oldPassword"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                        placeholder="Одоогийн нууц үг"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1" htmlFor="newPassword">
                        Шинэ нууц үг
                    </label>
                    <input
                        type="password"
                        id="newPassword"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                        placeholder="Шинэ нууц үг"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1" htmlFor="confirmPassword">
                        Нууц үг баталгаажуулах
                    </label>
                    <input
                        type="password"
                        id="confirmPassword"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                        placeholder="Нууц үг баталгаажуулах"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-primary text-white font-bold py-2 rounded-lg hover:bg-primary-dark transition-colors"
                >
                    Хадгалах
                </button>
            </form>
        </>
    );
};

export default ChangePassword;
