import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/icon/search.svg";
import { ReactComponent as Bell } from "../../assets/icon/bell.svg";
import { ReactComponent as User } from "../../assets/icon/user.svg";
import { ReactComponent as Question } from "../../assets/icon/question.svg";
import { ReactComponent as LogOut } from "../../assets/icon/log-out.svg";
import { ReactComponent as Gear } from "../../assets/icon/gear.svg";
import { ReactComponent as ChevronRight } from "../../assets/icon/chevron-right-1.svg";
import { Icon } from "../utils/menuIcons";
import { twMerge } from "tailwind-merge";
import { useStore } from "../../stores";
import IconButton from "../../base-components/IconButton";
import Dropdown from "../../components/Dropdown";
import Typography from "../../base-components/Typography";
import CustomizedTooltip from "../../base-components/Tooltip";
import { dispatch, useSelector } from "../../redux/store";
import { logout } from "../../redux/slices/auth";
import NotificationPopover from "../../pages/notification";

const menus = [
  {
    id: "home",
    title: "Нүүр хуудас",
    path: "/home",
  },
  {
    id: "user",
    title: "Хэрэглэгч",
    path: "/user",
  },
  {
    id: "account-management",
    title: "Дансны удирдлага",
    path: "/account-management",
  },
  {
    id: "savings",
    title: "Хуримтлал",
    path: "/savings",
  },
];

function Layout() {
  const navigate = useNavigate();
  const user = useStore.useUser();
  const token = useStore.useToken();
  const setToken = useStore.useSetToken();
  const selectedMenu = useStore.useSelectedMenu();
  const setSelectedMenu = useStore.useSetSelectedMenu();
  const [isShowing, setIsShowing] = useState(false);
  const [isNotificatinoShow, setShowNotification] = useState(false);
  const { logged, username } = useSelector(state => state.auth);

  useEffect(() => {
    setSelectedMenu(window.location.pathname);
  }, []);

  const selectMenu = (menu) => {
    setSelectedMenu(menu.path);
    if (menu.path === "/invite-friend") navigate("coming-soon");
    else navigate(menu.path);
  };

  useEffect(() => {
    if (!logged) {
      navigate('/login');
    }
  }, [logged, navigate]);

  if (logged)
    return (
      <div className="flex flex-col h-screen">
        <div className="flex flex-grow">
          {/* Sidebar */}
          <div className="bg-primary py-7 px-8 max-lg:px-4 relative max-[500px]:hidden border-b">
            <div className="px-4 py-2.5">
              <Typography variant="Title" className="text-white">
                Гэрэгэ
              </Typography>
            </div>
            <div className="mt-16">
              {menus.map((menu, index) => (
                <div
                  key={index}
                  className={twMerge([
                    "group flex items-center mt-4 py-2.5 px-4 space-x-4 rounded-lg cursor-pointer transition-all w-52 hover:bg-surface-gray/[0.12]",
                    "max-lg:flex-col max-lg:w-24 max-lg:space-y-2 max-lg:space-x-0",
                    menu.path === selectedMenu
                      ? "bg-white text-primary"
                      : "hover:bg-surface-gray/[0.12]",
                  ])}
                  onClick={() => selectMenu(menu)}
                >
                  {Icon({ id: menu.id, isSelected: menu.path === selectedMenu })}
                  <span
                    className={twMerge([
                      "transition-color",
                      "max-lg:text-center",
                      menu.path === selectedMenu
                        ? "text-primary"
                        : "text-white group-hover:text-white-24",
                    ])}
                  >
                    {menu.title}
                  </span>
                </div>
              ))}
            </div>

            <div className="absolute bottom-16">
              <div
                className={twMerge([
                  "flex items-center mt-4 py-2.5 px-4 space-x-4 rounded-lg cursor-pointer transition-all w-52 hover:bg-surface-gray/[0.12]",
                  "max-lg:flex-col max-lg:w-24 max-lg:space-y-2 max-lg:space-x-0",
                ])}
              >
                <Question stroke="#fff" className="w-5 h-5" />
                <span className={"text-white ml-4 transition-color"}>
                  Тусламж
                </span>
              </div>
              <div
                className={twMerge([
                  "flex items-center mt-4 py-2.5 px-4 space-x-4 rounded-lg cursor-pointer transition-all w-52 hover:bg-surface-gray/[0.12]",
                  "max-lg:flex-col max-lg:w-24 max-lg:space-y-2 max-lg:space-x-0",
                ])}
                onClick={() => dispatch(logout(navigate))}
              >
                <LogOut stroke="#fff" className="w-5 h-5" />
                <span className={"text-white ml-4 transition-color"}>Гарах</span>
              </div>
            </div>
          </div>

          {/* Main Content Area */}
          <div className="w-full flex flex-col">
            <div className="flex justify-between p-4 px-16 max-[500px]:px-4">
              <div className="relative flex items-center">
                <SearchIcon stroke="#D9E0E8" className="absolute ml-2 w-5 h-5" />
                <input
                  className="rounded-full border border-stroke-dark-12 px-4 py-2 pl-8 bg-surface-gray outline-none text-primary text-sm"
                  placeholder="Хайх"
                />
              </div>
              <div className="flex items-center space-x-3">
                <NotificationPopover />
                <button
                  className="flex items-center space-x-2"
                  onClick={() => setIsShowing(!isShowing)}
                >
                  <IconButton variant="outlinePrimary" size="sm">
                    <User stroke="primary" className="h-5 w-5" />
                  </IconButton>
                  <span className="max-[500px]:hidden">{username}</span>
                </button>
                <Dropdown show={isShowing} className="mt-6">
                  <Dropdown.Header>
                    <div className="flex items-center space-x-2">
                      <IconButton variant="outlinePrimary" size="sm">
                        <User stroke="primary" className="h-5 w-5" />
                      </IconButton>
                      <span>{username}</span>
                    </div>
                    <ChevronRight />
                  </Dropdown.Header>
                  <Dropdown.Panel>
                    <div className="flex items-center px-4 py-3.5 hover:cursor-pointer">
                      <Question className="w-4 h-4" />
                      <Typography size="md" className="ml-4">
                        Тусламж
                      </Typography>
                    </div>
                    <div className="flex items-center px-4 py-3.5 hover:cursor-pointer">
                      <Gear className="w-4 h-4" />
                      <Typography size="md" className="ml-4">
                        Тохиргоо
                      </Typography>
                    </div>
                    <div
                      className="flex items-center px-4 py-3.5 hover:cursor-pointer"
                      onClick={() => dispatch(logout(navigate))}
                    >
                      <LogOut stroke="#B7281E" className="w-4 h-4" />
                      <Typography size="md" className="ml-4 text-error">
                        Гарах
                      </Typography>
                    </div>
                  </Dropdown.Panel>
                </Dropdown>
              </div>
            </div>
            <Outlet />

            {/* Mobile Footer */}
            <div className="hidden max-[500px]:flex justify-evenly bg-primary fixed bottom-0 right-0 left-0">
              {menus.map((menu, index) => (
                <CustomizedTooltip
                  key={index}
                  title={menu.title}
                  placement="bottom-end"
                >
                  <div
                    className={twMerge([
                      "flex flex-col items-center space-y-2 max-[353px]:my-2 p-4 cursor-pointer transition-all rounded-lg truncate hover:bg-white/[0.04]",
                      "w-min",
                      menu.path === selectedMenu && "bg-white/[0.08]",
                    ])}
                    onClick={() => selectMenu(menu)}
                  >
                    {Icon({ id: menu.id, isSelected: menu.path === selectedMenu, mobile: true })}
                    <Typography
                      variant="Label"
                      size="md"
                      className={twMerge([
                        "transition-color max-[353px]:hidden",
                        menu.path === selectedMenu
                          ? "text-secondary"
                          : "text-on-surface-black-64",
                      ])}
                    >
                      {menu.title}
                    </Typography>
                  </div>
                </CustomizedTooltip>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
}

export default Layout;
