import { useEffect } from "react";
import { formatDate } from "../../utils/fomatter";
import { dispatch, useSelector } from "../../redux/store";
import { notificationListThunk } from "../../redux/slices/notification";

const NotificationDetailModal = ({ visible, onClose, notification }) => {
    const { notificationDetailSuccess } = useSelector(state => state.notification)
    useEffect(() => {
        console.log("notificationDetailSuccess", notificationDetailSuccess);
        if (notificationDetailSuccess) {

            dispatch(notificationListThunk())
        }
    }, [notificationDetailSuccess])

    if (!visible || !notification) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-96">
                <div className="flex justify-between items-center p-4 border-b">
                    <h3 className="text-lg font-semibold">{notification.title || 'Мэдэгдлийн дэлгэрэнгүй'}</h3>
                    <button onClick={onClose} className="text-gray-600 hover:text-black">&times;</button>
                </div>
                <div className="p-4">
                    <p className="text-gray-700 mb-2">{notification.body}</p>
                    <div className="text-sm text-gray-500"> {formatDate(notification.createdDate)}</div>
                    {notification.sender && <div className="text-sm text-gray-500">Sender: {notification.sender}</div>}
                    {notification.priority && <div className="mt-2">Priority: <strong>{notification.priority}</strong></div>}
                </div>
                <div className="flex justify-end p-4 border-t">
                    <button onClick={onClose} className="px-4 py-2 bg-primary text-white rounded-lg">Хаах</button>
                </div>
            </div>
        </div>
    );
};

export default NotificationDetailModal;
