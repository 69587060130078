import { TbHome, TbHomeFilled } from "react-icons/tb";
import {
  MdAccountBalance,
  MdOutlineAccountBalance,
  MdOutlineSavings,
  MdSavings,
} from "react-icons/md";
import { FaRegUser, FaUser } from "react-icons/fa";

export function Icon({ id, isSelected, mobile }) {
  switch (id) {
    case "home":
      if (isSelected && !mobile) return <TbHomeFilled className="w-5 h-5 primary" />;
      else return <TbHome className="w-5 h-5 text-white" />;
    case "user":
      if (isSelected && !mobile) return <FaUser className="w-5 h-5 primary" />;
      else return <FaRegUser className="w-5 h-5 text-white" />;
    case "account-management":
      if (isSelected && !mobile) return <MdAccountBalance className="w-5 h-5 primary" />;
      else return <MdOutlineAccountBalance className="w-5 h-5 text-white" />;
    case "savings":
      if (isSelected && !mobile) return <MdSavings className="w-5 h-5 primary" />;
      else return <MdOutlineSavings className="w-5 h-5 text-white" />;
    default:
      break;
  }
}
