import React from "react";

const RegisteredDevices = () => {
    // Sample data for registered devices
    const devices = [
        {
            deviceId: "12345",
            deviceName: "iPhone 12",
            deviceType: "Гар утас",
            os: "iOS 15.0",
            lastAccess: "2024.03.12",
            location: "Улаанбаатар, Монгол",
        },
        {
            deviceId: "67890",
            deviceName: "MacBook Pro",
            deviceType: "Компьютер",
            os: "macOS 11.4",
            lastAccess: "2024.03.10",
            location: "Улаанбаатар, Монгол",
        },
        {
            deviceId: "54321",
            deviceName: "Samsung Galaxy S21",
            deviceType: "Гар утас",
            os: "Android 11",
            lastAccess: "2024.03.08",
            location: "Дархан, Монгол",
        },
    ];

    return (
        <div className="max-w-4xl mx-auto mt-10 px-4 sm:px-6 lg:px-8">
            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4">Бүртгэлтэй төхөөрөмжүүд</h2>

                {/* Table for larger screens */}
                <div className="hidden md:block overflow-x-auto">
                    <table className="w-full table-auto text-left border-collapse">
                        <thead>
                            <tr className="text-gray-700 bg-gray-200">
                                <th className="px-4 py-2">Төхөөрөмжийн нэр</th>
                                <th className="px-4 py-2">Төхөөрөмжийн төрөл</th>
                                <th className="px-4 py-2">Үйлдлийн систем</th>
                                <th className="px-4 py-2">Сүүлд хандсан</th>
                                <th className="px-4 py-2">Байршил</th>
                            </tr>
                        </thead>
                        <tbody>
                            {devices.map((device, index) => (
                                <tr
                                    key={index}
                                    className="bg-white border-b hover:bg-gray-50"
                                >
                                    <td className="px-4 py-2">{device.deviceName}</td>
                                    <td className="px-4 py-2">{device.deviceType}</td>
                                    <td className="px-4 py-2">{device.os}</td>
                                    <td className="px-4 py-2">{device.lastAccess}</td>
                                    <td className="px-4 py-2">{device.location}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Card view for mobile with vertical scroll */}
                <div className="block md:hidden space-y-4 h-[75vh] overflow-y-auto">
                    {devices.map((device, index) => (
                        <div
                            key={index}
                            className="bg-gray-100 p-4 rounded-lg shadow-md"
                        >
                            <p>
                                <span className="font-semibold">Төхөөрөмжийн нэр:</span>{" "}
                                {device.deviceName}
                            </p>
                            <p>
                                <span className="font-semibold">Төхөөрөмжийн төрөл:</span>{" "}
                                {device.deviceType}
                            </p>
                            <p>
                                <span className="font-semibold">Үйлдлийн систем:</span>{" "}
                                {device.os}
                            </p>
                            <p>
                                <span className="font-semibold">Сүүлд хандсан:</span>{" "}
                                {device.lastAccess}
                            </p>
                            <p>
                                <span className="font-semibold">Байршил:</span>{" "}
                                {device.location}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RegisteredDevices;
