import React from "react";

const TermsAndContract = () => {
    return (
        <div className="max-w-4xl mx-auto mt-6 md:mt-10 p-4">
            {/* Service Terms Section */}
            <div className="bg-gray-200 p-4 md:p-6 mb-6 rounded-lg">
                <h2 className="text-lg md:text-xl font-semibold mb-4">Үйлчилгээний нөхцөл</h2>
                <div className="bg-white p-4 h-48 md:h-64 rounded-lg shadow-md overflow-y-scroll">
                    {/* Content for Service Terms */}
                    <p>
                        Үйлчилгээний нөхцөл - content goes here. If you add enough text,
                        this section will scroll. Add more content to see the scrolling
                        effect. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                        ut aliquip ex ea commodo consequat.
                    </p>
                </div>
            </div>

            {/* Digital Contract Section */}
            <div className="bg-gray-200 p-4 md:p-6 rounded-lg">
                <h2 className="text-lg md:text-xl font-semibold mb-4">Цахим гэрээ</h2>
                <div className="bg-white p-4 h-48 md:h-64 rounded-lg shadow-md overflow-y-scroll">
                    {/* Content for Digital Contract */}
                    <p>
                        Цахим гэрээ - content goes here. If the content exceeds the fixed
                        height, this section will scroll. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Quisque rutrum, nulla a euismod
                        pharetra, nulla nisi viverra neque, vitae sodales velit odio nec
                        risus.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsAndContract;
