import { createSlice } from "@reduxjs/toolkit";
// utils
import { dispatch } from "../store";

//services
import NotificationService from "../../services/NotificationService";

// ----------------------------------------------------------------------

const initialState = {
    loading: false,
    detailLoading: false,
    error: null,
    success: false,
    notifications: [],
    notificationDetailSuccess: false,
};

const slice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        startDetailLoading(state) {
            state.detailLoading = true;
        },
        stopDetailLoading(state) {
            state.detailLoading = false;
        },
        notificationListSuccess(state, action) {
            console.log("dataniyubaina", action);
            state.notifications = action.payload
            console.log("dataniyubaina", state.notifications);
            state.loading = false;
            state.notificationDetailSuccess = false;
        },
        readNotificationSuccess(state, action) {
            console.log("datadata", action.payload);
            state.notificationDetailSuccess = true;
            state.detailLoading = false;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.detailLoading = false;
        },

        //Login hide error
        hideError(state) {
            state.error = null;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading, hideError, checkLogin, logout } =
    slice.actions;

export function notificationListThunk() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await NotificationService.notificationList();
            if (response && response.responseCode == "SUCCESS") {
                dispatch(slice.actions.notificationListSuccess(response.responseData));
            }
            else {
                dispatch(slice.actions.hasError(response.responseMsg));
            }
        } catch (error) {
            console.log("notificationListerror", error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function readNotification(notificationId, clickEvent) {
    return async () => {
        dispatch(slice.actions.startDetailLoading());
        try {
            await NotificationService.readNotification(notificationId);
            dispatch(slice.actions.readNotificationSuccess({ clickEvent }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}