import { createSlice } from "@reduxjs/toolkit";
import { tokenKey } from "../../utils/constants";
// utils
import { dispatch } from "../store";

//services
import RegisterService from "../../services/RegisterService";
// ----------------------------------------------------------------------

const initialState = {
    loading: false,
    error: null,
    success: false,
    otpResendSuccess: false,
    registerSuccess: false,
    step: 1,
    registerBody: {},
    otpLoading: false,
    // const [, setStep] = useState(1);
};

const slice = createSlice({
    name: "register",
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setStep(state, action) {
            state.step = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.loading = false;
            console.log("error ni yu ireed bna", action);
            state.error = action.payload;
        },
        //Check register success
        checkRegisterSuccess(state, action) {
            state.loading = false;
            console.log("action ni yu ireed bna", action);
            state.success = true;
            state.step = state.step + 1;
            state.error = null;
            state.registerBody = {
                phoneNumber: action.payload
            }
        },
        //OTP verify success
        otpVerifySuccess(state, action) {
            state.loading = false;
            console.log("action ni yu ireed bna", action);
            state.success = true;
            state.step = state.step + 1;
            state.error = null;

        },
        //OTP resend success
        otpResendSuccess(state, action) {
            state.loading = false;
            console.log("action ni yu ireed bna", action);
            state.otpResendSuccess = true;
            state.error = null;
        },
        initialStateRegister(state) {
            state.loading = false;
            state.error = null;
            state.success = false;
            state.step = 1;
            state.otpResendSuccess = false;
            state.registerSuccess = false;
        },
        createCustomerSuccess(state) {
            state.error = null;
            state.registerSuccess = true;
            state.loading = false;
        },
        hideError(state) {
            state.error = null;
            state.otpResendSuccess = false;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading, hideError, setStep, initialStateRegister } =
    slice.actions;

// // ----------------------------------------------------------------------

export function checkRegisterThunk(phoneNumber) {
    console.log("body ni yu baina", phoneNumber);
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await RegisterService.checkPhoneNumber({ phoneNumber });
            dispatch(slice.actions.checkRegisterSuccess({ response, phoneNumber }));
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            // dispatch(slice.actions.checkRegisterSuccess(phoneNumber));
        }
    };
}


export function otpVeriyThunk({ phoneNumber, otpValue }) {
    console.log("body ni yu baina", phoneNumber);
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await RegisterService.otpVerify({ phoneNumber, otpValue });
            dispatch(slice.actions.otpVerifySuccess());
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            console.log("error ni yu baina", error);
            // dispatch(slice.actions.otpVerifySuccess());
        }
    };
}

export function otpResendThunk({ phoneNumber }) {
    console.log("body ni yu baina", phoneNumber);
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await RegisterService.otpResend(phoneNumber);
            dispatch(slice.actions.otpResendSuccess());
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            console.log("error ni yu baina", error);
            // dispatch(slice.actions.otpResendSuccess());
        }
    };
}
export function createCustomerThunk(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await RegisterService.createCustomer(data);
            dispatch(slice.actions.createCustomerSuccess());
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            console.log("error ni yu baina", error);
            // dispatch(slice.actions.createCustomerSuccess());
        }
    };
}