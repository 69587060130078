import Service from "./BaseService";

const notificationList = (config) => Service.get(`/api/v1/notification/list`, { ...config, nonLoading: true }, 8080);



const readNotification = (notificationId, config,) => Service.get(`/api/v1/notification/read?id=${notificationId}`, { ...config, nonLoading: true }, 8080);



const NotificationService = {
    notificationList,
    readNotification,
}

export default NotificationService;