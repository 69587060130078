import React, { useEffect, useRef, useState } from "react";

import Input from "../../../base-components/Input";
import Typography from "../../../base-components/Typography";

import { dispatch, useSelector } from "../../../redux/store";
import {
    hideError,
} from "../../../redux/slices/register";
import { forgotOtpResendThunk } from "../../../redux/slices/forgot";
import { deviceOtpVerify } from "../../../redux/slices/auth";
import { useNavigate } from "react-router-dom";

let currentOtpIndex = 0;
const DeviceVerify = () => {
    const { error, username } = useSelector((state) => state.auth);

    // const {
    //     formState: { errors },
    //     getValues,
    //     // setError,
    //     // setValue,
    // } = useFormContext();
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [activeOtpIndex, setActiveOtpIndex] = useState(0);
    const [timeLeft, setTimeLeft] = useState(60);
    const [isActiveInterval, setIsActiveInterval] = useState(true);

    const otpRef = useRef(null);

    const navigate = useNavigate();
    useEffect(() => {
        setActiveOtpIndex(0);
    }, []);

    useEffect(() => {
        let interval = null;
        if (isActiveInterval && timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft((time) => time - 1);
            }, 1000);
        } else if (!isActiveInterval || timeLeft === 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActiveInterval, timeLeft]);

    useEffect(() => {
        otpRef.current?.focus();
    }, [activeOtpIndex]);

    const verifyCode = (param) => {
        console.log("param123", param);
        // dispatch(setStep(step + 1));
        dispatch(
            deviceOtpVerify({
                "optValue": param,
                // "userAgent": "ganbat's iPhone",
                // "fcmToken": "ganbat"
            }, navigate)
        );
    };
    const handleChange = ({ target }) => {
        dispatch(hideError(null));
        const { value } = target;
        const newOtp = [...otp];
        newOtp[currentOtpIndex] = value.substring(value.length - 1);

        if (!value) setActiveOtpIndex(currentOtpIndex - 1);
        else setActiveOtpIndex(currentOtpIndex + 1);

        setOtp(newOtp);
        let param = newOtp.toString().replace(/,/g, "");
        if (newOtp.filter((x) => x !== "").length === 6) {
            verifyCode(param);
        }
    };
    const handleKeyDown = ({ key }, index) => {
        currentOtpIndex = index;
        if (key === "Backspace") setActiveOtpIndex(currentOtpIndex - 1);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
            2,
            "0"
        )}`;
    };

    return (
        <>
            <div>
                <Typography variant="Headline" size="md">
                    OTP
                </Typography>
                <Typography type="softPrimary" className="max-w-[333px]">
                    Таны {username} дугаарт 6 оронтой код илгээгдлээ
                </Typography>
            </div>
            <div className="flex items-center justify-center space-x-2">
                {otp.map((_, index) => (
                    <React.Fragment key={index}>
                        <Input
                            ref={index === activeOtpIndex ? otpRef : null}
                            type={index > 3 ? "text" : "number"}
                            className="w-14 h-14 rounded py-0 text-center text-xl"
                            value={otp[index]}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                    </React.Fragment>
                ))}
            </div>

            {error && <p className="text-error text-xs pl-2 mt-1">{error}</p>}

            <div className="flex items-center justify-between mb-20">
                <Typography className="text-center">{formatTime(timeLeft)}</Typography>
                {timeLeft == 0 && (
                    <button
                        className="outline-none [&:hover:not(:disabled)]:underline disabled:text-gray"
                        onClick={() => {
                            setTimeLeft(60);
                            setIsActiveInterval(true);
                            dispatch(forgotOtpResendThunk({ phoneNumber: username }))
                        }}
                    >
                        <Typography>Ахин илгээх</Typography>
                    </button>
                )}
            </div>
        </>
    );
};

export default DeviceVerify;
