// src/App.js
import React, { useEffect, useState } from 'react';
import NotificationList from './notifcation-list';
import NotificationDetailModal from './notification-detail-modal';
import { dispatch, useSelector } from '../../redux/store';
import { notificationListThunk, readNotification } from '../../redux/slices/notification';

function NotificationPopover() {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    // const notifications1 = [
    //     { id: 1, message: 'New message from John Doe', date: '2024-10-16', read: false },
    //     { id: 2, message: 'Application update available', date: '2024-10-15', read: true },
    //     { id: 3, message: 'Meeting at 3 PM', date: '2024-10-14', read: false },
    // ];
    const { notifications, loading } = useSelector(state => state.notification)
    const handleSelectNotification = (notification) => {
        setSelectedNotification(notification);
        dispatch(readNotification(notification.id))
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedNotification(null);
    };

    useEffect(() => {
        dispatch(notificationListThunk())
    }, [dispatch])


    return (
        <div className="App">
            <NotificationList loading={loading} notifications={notifications} modalVisible={modalVisible} onSelect={handleSelectNotification} />
            <NotificationDetailModal
                visible={modalVisible}
                notification={selectedNotification}
                onClose={handleCloseModal}
            />
        </div>
    );
}

export default NotificationPopover;
