import Home from "./home";

const Dashboard = () => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow pb-24 md:pb-0">
        <Home />
      </div>
    </div>
  );
};

export default Dashboard;
