import Service from "./BaseService";

const login = (payload) => Service.post(`/auth/login`, payload);

const getOtpBySms = (mobile, config) => Service.get(`/auth/get-otp-by-sms?mobile=${mobile}`, { ...config, nonLoading: true });

const checkOtp = (payload, config) => Service.post(`/auth/check-otp`, payload, { ...config, nonLoading: true });

const resetPassword = (payload, config) => Service.post(`/auth/reset-password`, payload, { ...config, nonLoading: true });

const otpDeliveryOtp = (otpValue, config) => Service.get(`/auth/device/verify?otpValue=${otpValue}`, { ...config, nonLoading: true });


const deviceOtp = (payload, config) => Service.post(`/auth/device/otp`, payload, { ...config, nonLoading: true });


const deviceOtpVerify = (payload, config) => Service.post(`/auth/device/verify`, payload, { ...config, nonLoading: true });



const AuthService = {
  login,
  getOtpBySms,
  checkOtp,
  resetPassword,
  otpDeliveryOtp,
  deviceOtp,
  deviceOtpVerify
}

export default AuthService;