import React, { useState } from "react";
import Button from "../../base-components/Button";

const ContactUsModal = ({ setContactUsModal }) => {
  // Form state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  //   const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Form validation
  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Нэрээ оруулна уу";
    if (!formData.email) newErrors.email = "И-мэйл хаягаа оруулна уу";
    if (!formData.message) newErrors.message = "Мэдээллээ оруулна уу";
    return newErrors;
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      console.log("Form Data Submitted:", formData);
      alert("Таны илгээсэн мэдээлэл амжилттай хүргэгдлээ!");

      // Reset form
      setFormData({
        name: "",
        email: "",
        message: "",
      });
      setErrors({});
      // Close modal after submission
    }
  };

  return (
    <div>
      {/* Button to open the modal */}
      {/* Modal */}
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 relative">
          <h2 className="text-2xl font-semibold mb-4">Санал хүсэлт</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium mb-1">
                Нэр
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className={`w-full px-4 py-2 border ${
                  errors.name ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-primary`}
                placeholder="Таны нэр"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1">{errors.name}</p>
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium mb-1">
                И-мэйл
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className={`w-full px-4 py-2 border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-primary`}
                placeholder="Таны и-мэйл"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm font-medium mb-1"
              >
                Мэдээлэл
              </label>
              <textarea
                id="message"
                name="message"
                className={`w-full px-4 py-2 border ${
                  errors.message ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-primary`}
                placeholder="Таны илгээх мэдээлэл"
                rows="6"
                value={formData.message}
                onChange={handleChange}
              />
              {errors.message && (
                <p className="text-red-500 text-sm mt-1">{errors.message}</p>
              )}
            </div>

            <div className="flex justify-end space-x-2">
              {/* Close button */}
              {/* <button
                type="button"
                onClick={() => setContactUsModal(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
              >
                Хаах
              </button> */}
              <Button
                onClick={() => setContactUsModal(false)}
                variant="outlinePrimary"
                disabled={false}
                type="submit"
              >
                Хаах
              </Button>
              {/* <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-700 transition-colors"
              >
                Илгээх
              </button> */}
              <Button disabled={false} type="submit">
                Илгээх
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsModal;
