import React from "react";
import SavingsSection from "./savings";

const CardSection = () => {
  const cardBalance = 575000;
  const debitCardBalance = 512000;

  return (
    <div className="bg-gray-200 p-6 rounded-lg shadow-md">
      <h2 className="font-bold text-xl mb-4">Миний карт</h2>
      <p className="text-3xl font-semibold mb-6">
        {cardBalance.toLocaleString()}
      </p>
      <div className="bg-primary p-4 rounded-lg mb-6">
        <p className="font-semibold text-sm text-white">Дансны төрөл</p>
        <p className="text-sm text-white">Debit Card</p>
        <div className="flex justify-between items-center mt-2 text-white">
          <span>**** ********123</span>
          <span className="text-lg font-semibold">
            {debitCardBalance.toLocaleString()}
          </span>
        </div>
      </div>
      <SavingsSection />
    </div>
  );
};

export default CardSection;
