import { Transition } from "@headlessui/react";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

const Dropdown = forwardRef(({ show, className, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className="relative z-10"
    >
      <Transition
        show={show}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className={twMerge(["absolute right-0 mt-2 w-56 rounded-xl bg-white shadow-card py-2 px-4 border", className])}>
          {props.children}
        </div>
      </Transition>
    </div>
  )
})

Dropdown.Button = ({ className, ...props }) => {
  return (
    <>
      {props.children}
    </>
  )
}

Dropdown.Header = ({ className, ...props }) => {
  return (
    <div className={twMerge([
      "flex items-center justify-between cursor-pointer",
      className
    ])}>
      {props.children}
    </div>
  )
}

Dropdown.Panel = ({ className, ...props }) => {
  return (
    <div className={twMerge(["divide-y", className])}>
      {props.children}
    </div>
  )
}


Dropdown.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string
}

export default Dropdown;