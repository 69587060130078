import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa"; // Importing the back icon
import Button from "../../base-components/Button";
import { Doughnut } from "react-chartjs-2"; // Import Doughnut for the donut chart
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LinearScale,
} from "chart.js";
import GradientAreaChart from "./gradient_chart";

// Register the necessary components
ChartJS.register(Title, Tooltip, Legend, ArcElement, LinearScale);

const MySavings = () => {
  const navigate = useNavigate();
  const canvasRef = useRef(null); // Reference for the canvas

  const donutData = {
    labels: ["Хаан банк", "Голомт банк", "Хас банк"],
    datasets: [
      {
        data: [50, 30, 20],
        backgroundColor: ["#0088FE", "#00C49F", "#FFBB28"],
        hoverBackgroundColor: ["#0056b3", "#009c7d", "#c7a700"],
      },
    ],
  };

  // Options for the Donut chart
  const donutOptions = {
    maintainAspectRatio: false,
    cutout: "70%", // Adjusts the size of the hole in the center to create a donut shape
  };

  // Placeholder function for the back button
  const handleBack = () => {
    navigate("/home", { replace: true });
    console.log("Back button clicked");
  };

  return (
    <div className="container mx-auto p-4">
      <Button>
        <div onClick={handleBack} className="flex gap-2 items-center">
          <FaArrowLeft /> Буцах
        </div>
      </Button>

      {/* Main Container */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-2">
        {" "}
        {/* Adjust grid for larger screens */}
        {/* Left Column */}
        <div className="col-span-1 space-y-6">
          {/* Summary Box */}
          <div className="bg-primary text-white p-6 rounded-lg">
            <p>Нийт (MNT):</p>
            <h1 className="text-3xl font-bold">1,000,000</h1>
            <p>
              Нийт хадгаламж: <span className="font-bold">3</span>
            </p>
            <p>
              Нийт цугларсан хүү:{" "}
              <span className="text-green-400 font-bold">45,000</span>
            </p>
          </div>

          <div className="bg-gray-200 p-6 rounded-lg flex-grow">
            <h2 className="font-semibold mb-4">Хуримтлалын төрөл</h2>
            <div className="flex justify-center">
              <Doughnut
                data={donutData}
                options={donutOptions}
                width={200}
                height={200}
              />
            </div>
          </div>

          {/* 6-Month Comparison Box */}
          <div className="bg-gray-200 p-6 rounded-lg">
            <h2 className="font-semibold mb-4">6 сарын харьцуулалт</h2>
            <GradientAreaChart />
          </div>
        </div>
        {/* Right Column */}
        <div className="col-span-1 md:col-span-2 bg-gray-200 p-6 rounded-lg">
          <h2 className="font-semibold mb-4">Хуримтлал</h2>
          <div className="overflow-x-auto">
            <table className="table-auto w-full text-left border border-gray-300 bg-white shadow-lg">
              <thead className="bg-gray-300">
                <tr>
                  <th className="px-4 py-2 border-b border-gray-400">
                    Хадгаламж
                  </th>
                  <th className="px-4 py-2 border-b border-gray-400">
                    Хөрөнгө оруулалт
                  </th>
                  <th className="px-4 py-2 border-b border-gray-400">
                    Хуримтлагдсан хүү
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    bank: "Хаан банк",
                    investment: "500,000",
                    interest: "30,000",
                  },
                  {
                    bank: "Голомт банк",
                    investment: "300,000",
                    interest: "20,000",
                  },
                  {
                    bank: "Хас банк",
                    investment: "200,000",
                    interest: "10,000",
                  },
                  {
                    bank: "Хаан банк",
                    investment: "500,000",
                    interest: "30,000",
                  },
                  {
                    bank: "Голомт банк",
                    investment: "300,000",
                    interest: "20,000",
                  },
                  {
                    bank: "Хас банк",
                    investment: "200,000",
                    interest: "10,000",
                  },
                  {
                    bank: "Хаан банк",
                    investment: "500,000",
                    interest: "30,000",
                  },
                  {
                    bank: "Голомт банк",
                    investment: "300,000",
                    interest: "20,000",
                  },
                  {
                    bank: "Хас банк",
                    investment: "200,000",
                    interest: "10,000",
                  },
                  {
                    bank: "Хаан банк",
                    investment: "500,000",
                    interest: "30,000",
                  },
                  {
                    bank: "Голомт банк",
                    investment: "300,000",
                    interest: "20,000",
                  },
                  {
                    bank: "Хас банк",
                    investment: "200,000",
                    interest: "10,000",
                  },
                ].map((row, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="px-4 py-2">{row.bank}</td>
                    <td className="px-4 py-2">{row.investment}</td>
                    <td className="px-4 py-2">
                      {row.interest}
                      <span className="ml-2 bg-green-400 text-white px-2 py-1 rounded-full">
                        + 5%
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySavings;
